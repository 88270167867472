<script setup lang="ts">
import { PropType } from 'vue'
import { VueRecaptcha } from 'vue-recaptcha'
import {
  Dialog,
  DialogPanel,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { useStore as useAppStore } from '@/store/app'
import { ErrorTypes, FormStates, useStore } from '@/store/request-booking'
import {
  formatCurrency,
  formatDate,
  formatDatetimeFull,
  formatNumber,
  formatPhone,
  formatWeight,
  formatLocation,
} from '@/utils/format'
import { isDateBefore } from '@/utils/date-compare'
import SpinnerIcon from '@/components/SpinnerIcon.vue'
import CityStateTypeahead from '@/components/CityStateTypeahead.vue'
import LoadDetails from './LoadDetails.vue'
import ErrorDisplay from './ErrorDisplay.vue'
import RoutingDetails from './RoutingDetails.vue'
import StopsDetails from './StopsDetails.vue'

const props = defineProps({
  load: {
    type: Object as PropType<Load | null>,
    required: true,
  },
})

const store = useStore()
const appStore = useAppStore()
const emit = defineEmits(['close'])
const recaptchaSiteKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY as string
const today = formatDate(new Date(), 'yyyy-MM-dd')

const clientDisplayName = computed(() => appStore.settings.CLIENT_DISPLAY_NAME)
const shouldShowAllStops = computed(() => appStore.settings.BOOKING_MODAL_DISPLAY_ALL_STOPS)
const shouldShowSpecialInstructions = computed(
  () => appStore.settings.BOOKING_MODAL_DISPLAY_SPECIAL_INSTRUCTIONS,
)
const additionalDisclaimer = computed(() => appStore.settings.BOOKING_MODAL_ADDITIONAL_DISCLAIMER)

const load = computed(() => ({
  id: props.load?.id || 0,
  eqType: props.load?.eqType || { id: 0, desc: '' },
  commodity: props.load?.commodity || '',
  weight: formatWeight(props.load?.weight.value, props.load?.weight.type),
  pallets: formatNumber(props.load?.pallets || 0),
  origin: formatLocation(props.load?.origin.city, props.load?.origin.state),
  dest: formatLocation(props.load?.dest.city, props.load?.dest.state),
  miles: formatNumber(props.load?.miles || 0),
  pickupDatetime: formatDatetimeFull(props.load?.pickupDatetime || ''),
  deliveryDatetime: formatDatetimeFull(props.load?.deliveryDatetime || ''),
  contact: {
    email: props.load?.office.email || '',
    phone: formatPhone(props.load?.office.phone || '') ?? '',
  },
  rate: formatCurrency(props.load?.carrierRate || 0),
  stops: props.load?.stops,
  specialInstructions: props.load?.specialInstructions || '',
}))

const show = ref(false)
const dotNumInputRef = ref(null)
const isLoadingCarrier = ref(false)
const recaptcha = ref('')
const errors = ref<string[]>([])
const isCompanyNameRequired = ref(false)
const isFormSubmitting = computed(() => store.FORM_STATE === FormStates.PROCESSING)
const formData = reactive({
  carrierId: 0,
  dotNum: '',
  companyName: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  emptyLocation: { city: '', state: '' },
  dateAvailable: '',
  timeAvailable: '',
  acceptPostedRate: true,
  alternateOfferRate: 0,
})

// allows the transition to be triggered when the modal is activated
onMounted(() => {
  show.value = true
})

function resetForm() {
  formData.carrierId = 0
  formData.dotNum = ''
  formData.companyName = ''
  formData.firstName = ''
  formData.lastName = ''
  formData.email = ''
  formData.phone = ''
  formData.emptyLocation.city = ''
  formData.emptyLocation.state = ''
  formData.dateAvailable = ''
  formData.timeAvailable = ''
  formData.acceptPostedRate = true
  formData.alternateOfferRate = 0
  recaptcha.value = ''
}

function closeModal() {
  show.value = false

  // give enough time for the transition to fire and then emit the close event
  // tried using `nextTick` instead of a timeout but the transition still wasn't finishing
  // before the component got removed from the DOM and it was resulting in a jumpy transition
  setTimeout(() => emit('close'), 250)
}

let previousDotNum = ''
async function lookupCarrier() {
  // do nothing if the dot number hasn't changed
  if (formData.dotNum === previousDotNum) {
    return
  }

  // make sure the carrier id is cleared out if there is no dot number since we don't know the carrier
  if (!formData.dotNum.length) {
    formData.carrierId = 0
    return
  }

  previousDotNum = formData.dotNum
  formData.carrierId = 0
  formData.companyName = ''

  isLoadingCarrier.value = true

  const lookupCarrierResult = await store.lookupCarrier(formData.dotNum)

  if (lookupCarrierResult.isOk()) {
    const carrier = lookupCarrierResult.unwrap()
    formData.carrierId = carrier.id
    formData.companyName = carrier.name
  }

  isLoadingCarrier.value = false
}

let prevOfferRate = 0
function postToggleAcceptPostedRate() {
  if (formData.acceptPostedRate) {
    prevOfferRate = formData.alternateOfferRate
    formData.alternateOfferRate = 0
  } else {
    formData.alternateOfferRate = prevOfferRate
  }
}

function toggleFormattedPhoneNumber(format = false) {
  if (format) {
    formData.phone = formatPhone(formData.phone) as string
  } else {
    formData.phone = formData.phone.replace(/\D/g, '')
  }
}

function onSuccessfulRecaptcha(response: string) {
  recaptcha.value = response
}

async function submit(event?: Event) {
  if (event) {
    event.preventDefault()
  }

  errors.value = []

  if (!formData.acceptPostedRate && !formData.alternateOfferRate) {
    errors.value.push('Please accept the posted rate or enter an alternate offer rate.')
  }
  // pass false so that today's date is allowed
  if (isDateBefore(formData.dateAvailable, today, false)) {
    errors.value.push('Date Available cannot be in the past.')
  }
  if (!recaptcha.value) {
    errors.value.push('Please verify that you are not a robot.')
  }
  if (errors.value.length) {
    return
  }

  const submitResult = await store.submitRequestBookingForm({
    shipmentId: props.load?.id ?? 0,
    bookingRequest: {
      carrierId: formData.carrierId,
      dotNum: formData.dotNum,
      rate: formData.acceptPostedRate ? props.load?.carrierRate ?? 0 : formData.alternateOfferRate,
      equipmentTypeId: props.load?.eqType.id || 0,
      availableDatetime: `${formData.dateAvailable} ${formData.timeAvailable}`,
      carrierName: formData.companyName,
      emptyLocation: {
        city: formData.emptyLocation.city,
        state: formData.emptyLocation.state,
      },
      contact: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
      },
    },
    recaptcha: recaptcha.value,
  })

  submitResult.match({
    ok: (callrate) => {
      appStore.addNotification(
        {
          type: 'SUCCESS',
          header: 'Booking Request Submitted',
          body: `Booking request #${callrate.id} has been submitted!`,
        },
        5000,
      )
      resetForm()
      closeModal()
    },
    err: (err) => {
      switch (err) {
        case ErrorTypes.CREATE_CALLRATE_INVALID_RATE:
          errors.value.push('Please accept the posted rate or enter an alternate offer rate.')
          break
        case ErrorTypes.CREATE_CALLRATE_INVALID_RECAPTCHA:
          errors.value.push('Please verify that you are not a robot.')
          break
        case ErrorTypes.CREATE_CARRIER_BUILDER_ERROR:
        case ErrorTypes.CREATE_CARRIER_GENERAL_ERROR:
          isCompanyNameRequired.value = true
          errors.value.push(
            'Unable to automatically look up data for DOT number provided. Please provide a Company Name.',
          )
          break
        default:
          errors.value.push(
            'An unknown error occurred. Please try again or contact support if the problem persists.',
          )
          break
      }
    },
  })
}
</script>

<template>
  <TransitionRoot as="template" :show="show" v-if="load.id">
    <Dialog
      :initial-focus="dotNumInputRef"
      as="div"
      class="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto"
    >
      <div
        class="tw-flex tw-items-center tw-justify-center tw-min-h-screen tw-pt-4 tw-px-4 tw-pb-20 tw-text-center sm:tw-block sm:tw-p-0 tw-text-base"
      >
        <TransitionChild
          as="template"
          enter="tw-ease-out tw-duration-300"
          enter-from="tw-opacity-0"
          enter-to="tw-opacity-100"
          leave="tw-ease-in tw-duration-200"
          leave-from="tw-opacity-100"
          leave-to="tw-opacity-0"
        >
          <DialogOverlay
            class="tw-fixed tw-inset-0 tw-bg-gray-900/50 tw-transition-opacity tw-backdrop-blur-sm"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="tw-hidden sm:tw-inline-block sm:tw-align-middle sm:tw-h-screen">
          &#8203;
        </span>

        <TransitionChild
          as="template"
          enter="tw-ease-out tw-duration-300"
          enter-from="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
          enter-to="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
          leave="tw-ease-in tw-duration-200"
          leave-from="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
          leave-to="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
        >
          <DialogPanel
            as="form"
            @submit="submit"
            class="tw-inline-block tw-align-bottom tw-bg-white tw-rounded-lg tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8 sm:tw-align-middle sm:tw-max-w-6xl sm:tw-w-full"
          >
            <div class="tw-border-b tw-border-gray-200 tw-py-4 tw-px-4">
              <DialogTitle as="h3" class="tw-text-2xl tw-leading-6 tw-font-medium tw-text-gray-900">
                Book Load {{ load.id }}
              </DialogTitle>
            </div>
            <div class="tw-block tw-absolute tw-top-0 tw-right-0 tw-pt-4 tw-pr-4">
              <button
                type="button"
                class="tw-bg-white tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-300"
                @click="closeModal"
                :disabled="isFormSubmitting"
              >
                <!-- Heroicon name: outline/x -->
                <svg
                  class="tw-h-6 tw-w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div
              class="tw-bg-white tw-px-4 tw-py-3 sm:tw-p-6 sm:tw-pb-4 tw-w-full tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-ml-4 sm:tw-text-lef"
            >
              <div>
                <LoadDetails :load="load" />

                <RoutingDetails :load="load" />

                <StopsDetails v-if="shouldShowAllStops" :stops="load.stops" />
                <div
                  class="tw-flex tw-flex-col tw-gap-6 tw-border-t tw-border-gray-200 tw-mt-4 tw-pt-4 tw-mx-auto"
                >
                  <div v-if="shouldShowSpecialInstructions && load.specialInstructions.length > 0">
                    <div class="tw-grid">
                      <label class="tw-font-semibold tw-mr-2">SPECIAL INSTRUCTIONS</label>
                      <span class="tw-tabular-nums">{{ load.specialInstructions }}</span>
                    </div>
                    <div
                      class="tw-flex tw-flex-col tw-gap-6 tw-border-t tw-border-gray-200 tw-mt-4 tw-pt-4 tw-mx-auto"
                    ></div>
                  </div>
                  <div class="tw-text-center tw-text-xl tw-font-semibold">Carrier Rate Offer</div>

                  <ErrorDisplay v-if="errors.length" :errors="errors" @close="errors = []" />

                  <fieldset
                    class="tw-grid tw-gap-y-4 tw-text-base tw-max-w-5xl tw-mx-6 lg:tw-mx-auto"
                    :disabled="isFormSubmitting"
                  >
                    <div class="form-row">
                      <div class="form-group">
                        <label for="bookingRequest-dotNum" class="required form-label">
                          DOT#:
                        </label>
                        <input
                          ref="dotNumInputRef"
                          id="bookingRequest-dotNum"
                          class="form-input"
                          type="text"
                          v-model="formData.dotNum"
                          required="true"
                          @blur="lookupCarrier"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group">
                        <label
                          for="bookingRequest-companyName"
                          :class="['form-label', isCompanyNameRequired && 'required']"
                        >
                          Company Name:
                        </label>
                        <div class="tw-relative tw-col-span-2">
                          <input
                            id="bookingRequest-companyName"
                            class="form-input readonly:tw-bg-slate-200 placeholder:tw-text-slate-800 placeholder:tw-font-semibold"
                            type="text"
                            v-model="formData.companyName"
                            :readonly="isLoadingCarrier"
                            :placeholder="isLoadingCarrier ? 'Looking up details...' : ''"
                            :required="isCompanyNameRequired"
                            autocomplete="off"
                          />
                          <div
                            v-if="isLoadingCarrier"
                            class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none"
                          >
                            <SpinnerIcon class="tw-animate-spin tw-h-5 tw-w-5" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group">
                        <label id="bookingRequest-firstName" class="required form-label">
                          First Name:
                        </label>
                        <input
                          for="bookingRequest-firstName"
                          class="form-input"
                          type="text"
                          required="true"
                          v-model="formData.firstName"
                          autocomplete="off"
                        />
                      </div>
                      <div class="form-group">
                        <label for="bookingRequest-lastName" class="required form-label">
                          Last Name:
                        </label>
                        <input
                          id="bookingRequest-lastName"
                          class="form-input"
                          type="text"
                          required="true"
                          v-model="formData.lastName"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group">
                        <label for="bookingRequest-email" class="required form-label">
                          Email:
                        </label>
                        <input
                          id="bookingRequest-email"
                          class="form-input"
                          type="email"
                          required="true"
                          v-model="formData.email"
                          autocomplete="off"
                        />
                      </div>
                      <div class="form-group">
                        <label for="bookingRequest-phone" class="required form-label">
                          Phone:
                        </label>
                        <input
                          id="bookingRequest-phone"
                          class="form-input"
                          type="tel"
                          required="true"
                          v-model="formData.phone"
                          @blur="toggleFormattedPhoneNumber(true)"
                          @focus="toggleFormattedPhoneNumber(false)"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group">
                        <label for="bookingRequest-emptyLocation" class="required form-label">
                          Empty Location:
                        </label>
                        <CityStateTypeahead
                          id="bookingRequest-emptyLocation"
                          class="tw-col-span-2"
                          v-model="formData.emptyLocation"
                          :required="true"
                        />
                      </div>
                      <div class="form-group">
                        <label for="bookingRequest-datetimeAvail" class="required form-label">
                          Date/Time Avail:
                        </label>
                        <div class="tw-col-span-2 tw-grid tw-grid-cols-2 tw-gap-2">
                          <input
                            id="bookingRequest-datetimeAvail"
                            class="tw-shadow-sm tw-block tw-w-full sm:tw-text-sm tw-border-gray-300 tw-rounded-md tw-tabular-nums"
                            type="date"
                            v-model="formData.dateAvailable"
                            :min="today"
                            required="true"
                            autocomplete="off"
                          />
                          <input
                            class="tw-shadow-sm tw-block tw-w-full sm:tw-text-sm tw-border-gray-300 tw-rounded-md tw-tabular-nums"
                            type="time"
                            v-model="formData.timeAvailable"
                            required="true"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group">
                        <label for="bookingRequest-acceptPostedRate" class="form-label">
                          Accept Posted Rate:
                        </label>
                        <div class="tw-flex tw-items-center tw-h-full">
                          <input
                            id="bookingRequest-acceptPostedRate"
                            type="checkbox"
                            class="tw-h-5 tw-w-5 tw-text-blue-500 tw-border-gray-300 tw-rounded"
                            v-model="formData.acceptPostedRate"
                            @change="postToggleAcceptPostedRate"
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="bookingRequest-altOfferRate" class="form-label">
                          Alt. Offer Rate:
                        </label>
                        <input
                          id="bookingRequest-altOfferRate"
                          class="form-input"
                          type="number"
                          v-model="formData.alternateOfferRate"
                          :disabled="formData.acceptPostedRate"
                          autocomplete="off"
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>

              <fieldset class="tw-flex tw-justify-center tw-mt-8" :disabled="isFormSubmitting">
                <VueRecaptcha @verify="onSuccessfulRecaptcha" :sitekey="recaptchaSiteKey" />
              </fieldset>

              <div
                class="tw-max-w-5xl tw-mx-auto tw-mt-8 tw-text-sm tw-text-red-600 tw-font-semibold"
              >
                Please note: Accepting this rate does not guarantee that you will be booked on this
                load, and it is not a binding agreement. A {{ clientDisplayName }} associate will
                follow up shortly with more information.
              </div>
              <div
                v-if="additionalDisclaimer.length > 0"
                class="tw-max-w-5xl tw-mx-auto tw-mt-8 tw-text-sm tw-text-red-600 tw-font-semibold"
              >
                {{ additionalDisclaimer }}
              </div>
            </div>

            <div
              class="tw-bg-gray-50 tw-px-4 tw-py-4 sm:tw-px-6 sm:tw-flex sm:tw-flex-row-reverse tw-gap-2"
            >
              <button
                type="submit"
                class="button tw-bg-blue-600 hover:tw-bg-blue-700 tw-text-white tw-font-bold"
                :disabled="isFormSubmitting"
              >
                <template v-if="!isFormSubmitting">Submit Booking Request</template>
                <template v-else>
                  <SpinnerIcon class="tw-animate-spin tw-h-5 tw-w-5 tw-mr-2" />
                  Submitting...
                </template>
              </button>

              <button
                type="button"
                class="button tw-mt-3 tw-border tw-border-gray-300 tw-shadow-sm tw-bg-white tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-ring-blue-300"
                @click="closeModal"
                :disabled="isFormSubmitting"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped lang="scss">
.required {
  @apply before:tw-content-['*'] before:tw-mr-0.5 before:tw-text-red-500;
}

.form-row {
  @apply tw-grid tw-gap-y-4 sm:tw-grid-cols-2 tw-gap-x-0 md:tw-gap-x-4;
}

.form-group {
  @apply tw-grid lg:tw-grid-cols-3 tw-gap-2;
}

.form-label {
  @apply tw-font-semibold tw-w-40 tw-flex tw-items-center lg:tw-justify-end;
}

.form-input {
  @apply tw-shadow-sm tw-block tw-w-full sm:tw-text-sm tw-border-gray-300 tw-rounded-md tw-tabular-nums tw-col-span-2;

  &:focus {
    @apply tw-outline-none tw-shadow;
  }
}

.button {
  @apply tw-inline-flex tw-justify-center tw-py-2 tw-px-4 tw-w-full tw-rounded-md tw-text-base;
  @apply sm:tw-mt-0 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm;

  &:focus {
    @apply focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2;
  }
}
</style>
