import axios from 'axios'

const searchCache: { [dotNum: string]: { id: number; name: string } } = {}
export async function search(dotNum = '') {
  const emptyCarrier = { id: 0, name: '' }

  // make sure the carrier id is cleared out if there is no dot number since we don't know the carrier
  if (!dotNum.length) {
    return emptyCarrier
  }

  if (searchCache[dotNum]) {
    return searchCache[dotNum]
  }

  const { data: response } = await axios.get<APIResponse<{ id: number; name: string }[]>>(
    `/v2/search/carriers?dotNumber=${dotNum}`,
  )
  const carriers = response.data

  if (carriers.length === 1) {
    searchCache[dotNum] = {
      id: carriers[0].id,
      name: carriers[0].name,
    }
  }

  return searchCache[dotNum] ?? emptyCarrier
}

export async function create(dotNum = '') {
  const emptyCarrier = { id: 0, name: '' }

  if (!dotNum.length) {
    return emptyCarrier
  }

  const { data: response } = await axios.post<APIResponse<Carrier>>(`/v1/carriers`, {
    dotNumber: dotNum,
  })

  return response.data as Carrier
}
