import axios from 'axios'

export async function get(page = 1, loads: Load[] = []) {
  const limit = 5000
  const { data: res } = await axios.get<APIResponse<Load[]>>(
    `/v1/boards/shipments?page=${page}&limit=${limit}`,
  )
  loads.push(
    ...res.data.map((load) => {
      const formattedLoad = {
        ...load,
        eqType: {
          id: load.eqType.id,
          desc: load.eqType.desc.toUpperCase(),
        },
      }

      formattedLoad.origin.city = load.origin.city.toUpperCase()
      formattedLoad.origin.state = load.origin.state.toUpperCase()
      formattedLoad.dest.city = load.dest.city.toUpperCase()
      formattedLoad.dest.state = load.dest.state.toUpperCase()

      formattedLoad.stops = load.stops.sort((stopA, stopB) => {
        return stopA.sequence - stopB.sequence
      })
      formattedLoad.stops.map((stop) => ({
        ...stop,
        city: stop.city.toUpperCase(),
        state: stop.state.toUpperCase(),
      }))

      return formattedLoad
    }),
  )

  if (res.links?.next) {
    await get(page + 1, loads)
  }

  return loads
}
