import { parseISO, format as formatDateFn } from 'date-fns'

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export function formatCurrency(amount: number) {
  return currencyFormatter.format(amount)
}

export function formatNumber(num: number | string) {
  return Number(num).toLocaleString()
}

export function formatPhone(phoneNum: number | string) {
  const cleaned = `${phoneNum}`.replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return ''
}

export function formatDate(date: Date, format: string) {
  return formatDateFn(date, format)
}

export function formatDatetime(date: string) {
  return date.length ? formatDateFn(parseISO(date), 'MM/dd/yy HH:mm') : ''
}

export function formatDatetimeFull(date: string) {
  return date.length ? formatDateFn(parseISO(date), 'MMMM d, yyyy @ HH:mm') : ''
}

export function formatLocation(city?: string, state?: string) {
  return [city, state].filter(Boolean).join(', ')
}
export function formatWeight(value?: number, type?: string) {
  return value ? `${formatNumber(value)} ${type}` : ''
}
