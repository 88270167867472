import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { Quasar } from 'quasar'
import quasarIconSet from 'quasar/icon-set/svg-ionicons-v6'
import axios from 'axios'
import { createPinia } from 'pinia'
import VueGTag from 'vue-gtag'
import Rollbar from 'rollbar'
import localforage from 'localforage'

import 'quasar/dist/quasar.css'
import './app.css'

import App from './App.vue'
import { routes } from './routes'

// set default for axios to use on each request
axios.defaults.baseURL =
  (import.meta.env.VITE_API_URL as string) || `${new URL(window.location.href).origin}/api`
axios.defaults.headers.common['X-QE-Source'] = `load_board`

// specify a specific name for localforage to use
localforage.config({
  name: 'load-board',
})

// create a router for the app
const router = createRouter({
  history: createWebHistory(),
  routes,
})

// create the app and tell it to use plugins
const app = createApp(App)
  .use(Quasar, {
    plugins: {}, // import Quasar plugins and add here
    iconSet: quasarIconSet,
  })
  .use(router)
  .use(createPinia())

// set up rollbar and send all errors there
const rollbarKey = import.meta.env.VITE_ROLLBAR_KEY as string
if (rollbarKey) {
  const rollbar = new Rollbar({
    accessToken: rollbarKey,
    captureUncaught: true,
    captureUnhandledRejections: true,
  })

  app.config.errorHandler = (err) => {
    rollbar.error(err as Error)
    throw err // rethrow
  }
}

// set up google analytics
const googleAnalyticsId = import.meta.env.VITE_GA_ID
if (googleAnalyticsId) {
  app.use(VueGTag, {
    config: {
      id: googleAnalyticsId,
      params: {
        anonymize_ip: true,
      },
    },
    router,
  })
}

// mount the app to the DOM
app.mount('#app')
