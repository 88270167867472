import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { getDistance, handleAxiosError } from '@/utils'
import { isDateBetween, isDateBefore, isDateAfter } from '@/utils/date-compare'
import { get as getTenant } from '@/api/tenant'
import { get as getLoads } from '@/api/loads'
import { getCoords } from '@/api/lookups'

export const enum AppStates {
  STARTING,
  LOADING,
  READY,
  TENANT_NOT_FOUND,
  UNAVAILABLE,
  LOADS_UNAVAILABLE,
}

export interface Filters {
  originCity: string
  originState: string
  originRadius: number | null
  originCoords: {
    lat: number
    lon: number
  }
  destCity: string
  destState: string
  destRadius: number | null
  destCoords: {
    lat: number
    lon: number
  }
  eqType: string
  pickupDateFrom: string
  pickupDateTo: string
}

interface RootState {
  APP_STATE: AppStates

  tenant: string
  logo: string
  companyName: string
  linkedTenantId: string
  settings: {
    CARRIER_BOOKING_REQUEST_ENABLED: boolean
    LINKED_TENANT_ID: string
    CLIENT_DISPLAY_NAME: string
    BOOKING_MODAL_DISPLAY_ALL_STOPS: boolean
    BOOKING_MODAL_DISPLAY_SPECIAL_INSTRUCTIONS: boolean
    BOOKING_MODAL_ADDITIONAL_DISCLAIMER: string
    NUM_PICKS_AND_DROPS_COLS_ENABLED: boolean
    SHOW_LOAD_BOARD_LEGAL_DISCLAIMER: boolean
  }
  loading: boolean
  notifications: AppNotification[]
  loads: Load[]
  filters: Filters
}

export const useStore = defineStore('app', {
  // arrow function recommended for full type inference
  state: () =>
    ({
      APP_STATE: AppStates.STARTING,

      tenant: '',
      settings: {
        CARRIER_BOOKING_REQUEST_ENABLED: false,
        LINKED_TENANT_ID: '',
        CLIENT_DISPLAY_NAME: '',
        BOOKING_MODAL_DISPLAY_ALL_STOPS: false,
        BOOKING_MODAL_DISPLAY_SPECIAL_INSTRUCTIONS: false,
        BOOKING_MODAL_ADDITIONAL_DISCLAIMER: '',
        NUM_PICKS_AND_DROPS_COLS_ENABLED: false,
        SHOW_LOAD_BOARD_LEGAL_DISCLAIMER: false,
      },
      logo: '',
      companyName: '',
      linkedTenantId: '',
      loading: false,
      notifications: [],
      loads: [],
      filters: {
        originCity: '',
        originState: '',
        originRadius: null,
        originCoords: { lat: 0, lon: 0 },
        destCity: '',
        destState: '',
        destRadius: null,
        destCoords: { lat: 0, lon: 0 },
        eqType: '',
        pickupDateFrom: '',
        pickupDateTo: '',
      },
    } as RootState),

  getters: {
    isTenantSetup(state) {
      return Boolean(state.tenant)
    },

    filteredLoads(state) {
      const areFiltersEmpty =
        !state.filters.originCity.length &&
        !state.filters.originState.length &&
        !state.filters.originRadius &&
        !state.filters.destCity.length &&
        !state.filters.destState.length &&
        !state.filters.destRadius &&
        !state.filters.eqType.length &&
        !state.filters.pickupDateFrom.length &&
        !state.filters.pickupDateTo.length

      if (areFiltersEmpty) {
        return state.loads
      }

      const originCityFilter = state.filters.originCity.toLowerCase()
      const originStateFilter = state.filters.originState.toLowerCase()
      const destCityFilter = state.filters.destCity.toLowerCase()
      const destStateFilter = state.filters.destState.toLowerCase()
      const eqTypeFilter = state.filters.eqType.toLowerCase()
      const pickupDateFromFilter = state.filters.pickupDateFrom.length
        ? new Date(`${state.filters.pickupDateFrom}T00:00:00`)
        : ''
      const pickupDateToFilter = state.filters.pickupDateTo.length
        ? new Date(`${state.filters.pickupDateTo}T23:59:59`)
        : ''

      const filterLoad = (load: Load) => {
        const originCityMatch = originCityFilter
          ? load.origin.city.toLowerCase().startsWith(originCityFilter)
          : true
        const originStateMatch = originStateFilter
          ? load.origin.state.toLowerCase().startsWith(originStateFilter)
          : true
        const destCityMatch = destCityFilter
          ? load.dest.city.toLowerCase().startsWith(destCityFilter)
          : true
        const destStateMatch = destStateFilter
          ? load.dest.state.toLowerCase().startsWith(destStateFilter)
          : true
        const eqTypeMatch = eqTypeFilter
          ? load.eqType.desc.toLowerCase().includes(eqTypeFilter)
          : true

        let pickupDateMatch = true
        if (pickupDateFromFilter && pickupDateToFilter) {
          pickupDateMatch = isDateBetween(
            load.pickupDatetime,
            pickupDateFromFilter,
            pickupDateToFilter,
          )
        } else if (pickupDateFromFilter) {
          pickupDateMatch = isDateAfter(
            load.pickupDatetime,
            state.filters.pickupDateFrom as unknown as Date,
          )
        } else if (pickupDateToFilter) {
          pickupDateMatch = isDateBefore(
            load.pickupDatetime,
            state.filters.pickupDateTo as unknown as Date,
          )
        }

        // since `saveFilters` will only set the coord properties if the city and state are valid, we can avoid
        // checking them here
        let isOriginWithinRadius = false
        if (
          state.filters.originRadius &&
          state.filters.originCoords.lat &&
          state.filters.originCoords.lon
        ) {
          const coords = {
            lat: load.origin.lat,
            lon: load.origin.lon,
          }
          isOriginWithinRadius =
            getDistance(state.filters.originCoords, coords) <= state.filters.originRadius
        }

        let isDestWithinRadius = false
        if (
          state.filters.destRadius &&
          state.filters.destCoords.lat &&
          state.filters.destCoords.lon
        ) {
          const coords = {
            lat: load.dest.lat,
            lon: load.dest.lon,
          }
          isDestWithinRadius =
            getDistance(state.filters.destCoords, coords) <= state.filters.destRadius
        }

        const isOriginLocationOrRadiusMatch =
          (originCityMatch && originStateMatch) || isOriginWithinRadius
        const isDestLocationOrRadiusMatch = (destCityMatch && destStateMatch) || isDestWithinRadius

        return (
          isOriginLocationOrRadiusMatch &&
          isDestLocationOrRadiusMatch &&
          eqTypeMatch &&
          pickupDateMatch
        )
      }

      return state.loads.filter(filterLoad)
    },
  },

  actions: {
    async authenticate(tenantId: string) {
      try {
        const tenantData = await getTenant(tenantId)

        this.tenant = tenantData.tenant
        this.logo = tenantData.logo
        this.companyName = tenantData.companyName
        this.settings = tenantData.settings
        this.linkedTenantId = tenantData.linkedTenantId

        axios.defaults.headers.common.Authorization = `Bearer ${tenantData.token}`
      } catch (err) {
        axios.defaults.headers.common.Authorization = ''

        const response = handleAxiosError(err)

        if (response?.status === 404) {
          this.APP_STATE = AppStates.TENANT_NOT_FOUND
        } else {
          this.APP_STATE = AppStates.UNAVAILABLE
        }

        return false
      }

      return true
    },

    async fetchLoads() {
      try {
        const previousState = this.APP_STATE
        this.APP_STATE = AppStates.LOADING

        const loads = await getLoads()
        this.loads = [...loads]

        // we don't want to use the previous state if it was that loads were unavailable now that we have loads
        this.APP_STATE =
          previousState === AppStates.LOADS_UNAVAILABLE ? AppStates.READY : previousState
      } catch (err) {
        handleAxiosError(err)
        this.APP_STATE = AppStates.LOADS_UNAVAILABLE
      }
    },

    async saveFilters({
      originCity = '',
      originState = '',
      originRadius = null,
      destCity = '',
      destState = '',
      destRadius = null,
      eqType = '',
      pickupDateFrom = '',
      pickupDateTo = '',
    }: {
      originCity?: string
      originState?: string
      originRadius?: number | null
      destCity?: string
      destState?: string
      destRadius?: number | null
      eqType?: string
      pickupDateFrom?: string
      pickupDateTo?: string
    } = {}) {
      this.filters.originCity = originCity
      this.filters.originState = originState
      this.filters.originRadius = originRadius
      this.filters.destCity = destCity
      this.filters.destState = destState
      this.filters.destRadius = destRadius
      this.filters.eqType = eqType
      this.filters.pickupDateFrom = pickupDateFrom
      this.filters.pickupDateTo = pickupDateTo

      if (
        this.filters.originCity.length &&
        this.filters.originState.length &&
        this.filters.originRadius
      ) {
        this.filters.originCoords = await getCoords(
          this.filters.originCity,
          this.filters.originState,
        )
      } else {
        this.filters.originCoords = { lat: 0, lon: 0 }
      }

      if (
        this.filters.destCity.length &&
        this.filters.destState.length &&
        this.filters.destRadius
      ) {
        this.filters.destCoords = await getCoords(this.filters.destCity, this.filters.destState)
      } else {
        this.filters.destCoords = { lat: 0, lon: 0 }
      }
    },

    addNotification(notification: AppNotification, clearDelay = 0) {
      const newNotification: AppNotification = {
        ...notification,
        id: !notification.id ? uuidv4() : notification.id,
      }
      this.notifications.push(newNotification)

      if (clearDelay) {
        setTimeout(() => {
          this.removeNotification(newNotification.id as string)
        }, clearDelay)
      }

      return newNotification
    },

    removeNotification(id: string) {
      const notificationIndex = this.notifications.findIndex((n) => n.id === id)

      if (notificationIndex > -1) {
        this.notifications.splice(notificationIndex, 1)
      }
    },

    popNotification() {
      this.notifications.pop()
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot))
}
