import { AxiosError } from 'axios'

interface Coords {
  lat: number
  lon: number
}

/**
 * Get the distance, in miles, between two points.
 *
 * This formula is from https://stackoverflow.com/a/21623206/899476 with some modifications (using miles instead of Km)
 *  and uses the Haversine formula for calculating the distance.
 */
export function getDistance(coords1: Coords, coords2: Coords) {
  const earthDiameter = 7918 // in miles
  const pi = 0.017453292519943295 // Math.PI / 180
  const { cos } = Math
  const a =
    0.5 -
    cos((coords2.lat - coords1.lat) * pi) / 2 +
    (cos(coords1.lat * pi) * cos(coords2.lat * pi) * (1 - cos((coords2.lon - coords1.lon) * pi))) /
      2

  return earthDiameter * Math.asin(Math.sqrt(a))
}

/**
 * Take in an error and log it if it isn't a connection aborted error. The AxiosResponse from
 * the error will be returned
 */
export function handleAxiosError(err: unknown) {
  if (!(err instanceof AxiosError)) {
    return new AxiosError('Unknown error').response
  }
  const axiosError = err as AxiosError
  const is400TypeError = axiosError.response?.status.toString().match(/^4/) ?? false

  // this ECONNABORTED error code happens when the user aborts the connect (reload, close tab, etc)
  if (axiosError.code !== 'ECONNABORTED' && !is400TypeError) {
    try {
      if (!process.env.VITEST) {
        console.error(err)
      }
    } catch {
      // process seems to not be defined at times. catch when that happens and
      // make sure to log the original error
      console.error(err)
    }
  }

  return axiosError.response
}

export function createBaselineLoaderPromise() {
  return new Promise((res) => {
    setTimeout(res, 500)
  })
}
