import { Result } from '@iwburns/tupperware'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { getLocation } from '@/api/lookups'
import { search as searchCarriers, create as createCarrier } from '@/api/carriers'
import { create as createCallRate } from '@/api/call-rates'
import { createBaselineLoaderPromise, handleAxiosError } from '@/utils'
import { formatDate } from '@/utils/format'
import { isDateBefore } from '@/utils/date-compare'

export const enum FormStates {
  READY,
  PROCESSING,
}

export const enum ErrorTypes {
  LOOKUP_CARRIER_GENERAL_ERROR,
  LOOKUP_LOCATION_GENERAL_ERROR,
  CREATE_CARRIER_BUILDER_ERROR,
  CREATE_CARRIER_GENERAL_ERROR,
  CREATE_CALLRATE_INVALID_RATE,
  CREATE_CALLRATE_INVALID_DATE_EARLY,
  CREATE_CALLRATE_INVALID_RECAPTCHA,
  CREATE_CALLRATE_GENERAL_ERROR,
}

export const useStore = defineStore('request-booking', {
  // arrow function recommended for full type inference
  state: () => ({
    FORM_STATE: FormStates.READY,
  }),

  actions: {
    async lookupCarrier(dotNum = '') {
      const carrier = { id: 0, name: '' }
      try {
        const [, searchResult] = await Promise.all([
          createBaselineLoaderPromise(),
          searchCarriers(dotNum),
        ])

        carrier.id = searchResult.id
        carrier.name = searchResult.name
      } catch (err) {
        handleAxiosError(err)
        return Result.err(ErrorTypes.LOOKUP_CARRIER_GENERAL_ERROR)
      }
      return Result.ok(carrier)
    },

    async lookupLocations(searchValue = '') {
      let locations: CityState[] = []
      try {
        locations = (await getLocation(searchValue)) as CityState[]
      } catch (err) {
        handleAxiosError(err)
        return Result.err(ErrorTypes.LOOKUP_LOCATION_GENERAL_ERROR)
      }
      return Result.ok(locations)
    },

    async submitRequestBookingForm({
      shipmentId = 0,
      bookingRequest = {
        carrierId: 0,
        dotNum: '',
        rate: 0,
        equipmentTypeId: 0,
        availableDatetime: '',
        carrierName: '',
        emptyLocation: {
          city: '',
          state: '',
        },
        contact: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        },
      },
      recaptcha = '',
    } = {}) {
      if (!bookingRequest.rate) {
        return Result.err(ErrorTypes.CREATE_CALLRATE_INVALID_RATE)
      }

      const today = formatDate(new Date(), 'yyyy-MM-dd')
      // pass false so that today's date is allowed
      if (isDateBefore(bookingRequest.availableDatetime, today, false)) {
        return Result.err(ErrorTypes.CREATE_CALLRATE_INVALID_DATE_EARLY)
      }

      if (!recaptcha.length) {
        return Result.err(ErrorTypes.CREATE_CALLRATE_INVALID_RECAPTCHA)
      }

      this.FORM_STATE = FormStates.PROCESSING

      let { carrierId } = bookingRequest

      if (!carrierId) {
        try {
          const [, carrier] = await Promise.all([
            createBaselineLoaderPromise(),
            createCarrier(bookingRequest.dotNum),
          ])
          carrierId = carrier.id
        } catch (err) {
          const response = handleAxiosError(err)
          const serverCarrierBuildErrorText =
            'The carrier building services have encountered an unknown error'

          // if the user didn't provide a name and we couldn't automatically build the carrier, then
          // let the user know so that they can provide the necessary information
          if (
            !bookingRequest.carrierName.length &&
            response?.status === 500 &&
            (response?.data as APIResponse).messages.includes(serverCarrierBuildErrorText)
          ) {
            return Result.err(ErrorTypes.CREATE_CARRIER_BUILDER_ERROR)
          }

          // if the user didn't provide a name and any error happened, then let the user
          // know. we'll try to create the call rate if a name was provided
          if (!bookingRequest.carrierName.length) {
            return Result.err(ErrorTypes.CREATE_CARRIER_GENERAL_ERROR)
          }
        } finally {
          this.FORM_STATE = FormStates.READY
        }
      }

      // if we don't have a carrier ID or name by this point, let the user know we couldn't build the carrier
      if (!(carrierId || bookingRequest.carrierName.length)) {
        return Result.err(ErrorTypes.CREATE_CARRIER_BUILDER_ERROR)
      }

      this.FORM_STATE = FormStates.PROCESSING

      try {
        const [, callrate] = await Promise.all([
          createBaselineLoaderPromise(),
          createCallRate({
            shipmentId,
            bookingRequest: { ...bookingRequest, carrierId },
            recaptcha,
          }),
        ])

        return Result.ok(callrate)
      } catch (err) {
        handleAxiosError(err)

        return Result.err(ErrorTypes.CREATE_CALLRATE_GENERAL_ERROR)
      } finally {
        this.FORM_STATE = FormStates.READY
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot))
}
