<script setup lang="ts">
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
// the import/extensions eslint plugin acts up when importing this. disable to work around
// eslint-disable-next-line import/extensions
import { FilterIcon, XIcon } from '@heroicons/vue/solid'
import { Filters, AppStates, useStore } from '@/store/app'

import AppNotification from '@/components/AppNotification.vue'
import LoadBoardHeader from '@/components/LoadBoardHeader.vue'
import LoadBoardFilters from '@/components/LoadBoardFilters.vue'
import LoadBoardTable from '@/components/LoadBoardTable.vue'
import RequestBookingModal from '@/components/RequestBooking/BookingModal.vue'

const store = useStore()

const showFilterSidebar = ref(false)
const showRequestBookingModal = ref(false)
const loadForBooking = ref<Load | null>(null)

function filterLoads(filters: Filters) {
  store.saveFilters(filters)
}

function clearFilters() {
  store.saveFilters()
  showFilterSidebar.value = false
}

function openRequestBookingModal(load: Load) {
  loadForBooking.value = load
  showRequestBookingModal.value = true
}

function closeRequestBookingModal() {
  loadForBooking.value = null
  showRequestBookingModal.value = false
}
</script>

<template>
  <LoadBoardHeader />

  <div class="tw-flex tw-flex-col tw-h-full">
    <div class="tw-bg-gray-100 tw-px-4 tw-py-1 lg:tw-py-2 tw-h-8 lg:tw-h-20">
      <div class="lg:tw-hidden">
        <div class="tw-flex tw-justify-end tw-text-gray-600">
          <span id="filterOpenIcon" class="tw-cursor-pointer" @click="showFilterSidebar = true">
            <FilterIcon class="tw-h-6 tw-w-7" />
          </span>
        </div>
        <TransitionRoot as="template" :show="showFilterSidebar">
          <Dialog
            class="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto"
            :open="showFilterSidebar"
            @close="showFilterSidebar = false"
          >
            <TransitionChild
              as="template"
              enter="tw-transition-opacity tw-ease-linear tw-duration-300"
              enter-from="tw-opacity-0"
              enter-to="tw-opacity-100"
              leave="tw-transition-opacity tw-ease-linear tw-duration-300"
              leave-from="tw-opacity-100"
              leave-to="tw-opacity-0"
            >
              <DialogOverlay class="tw-bg-black tw-z-20 tw-opacity-40 tw-fixed tw-inset-0" />
            </TransitionChild>

            <TransitionChild
              enter="tw-transition tw-ease-linear tw-duration-300 tw-transform"
              enter-from="tw-translate-x-full"
              enter-to="tw-translate-x-0"
              leave="tw-transition tw-ease-out tw-duration-300 tw-transform"
              leave-from="tw-translate-x-0"
              leave-to="tw-translate-x-full"
              class="tw-fixed tw-z-20 tw-top-0 tw-right-0 tw-transform tw-bg-gray-200 tw-p-4 tw-shadow-xl tw-h-screen tw-w-80"
            >
              <DialogTitle
                as="h3"
                class="tw-flex tw-justify-between tw-items-center tw-h-6 tw-text-gray-800 tw-text-base tw-font-semibold"
              >
                <span>Filter Loads</span>
                <span id="filterCloseIcon" @click="showFilterSidebar = false">
                  <XIcon class="tw-h-6 tw-w-6 tw-cursor-pointer tw-text-gray-600" />
                </span>
              </DialogTitle>

              <LoadBoardFilters
                v-if="showFilterSidebar"
                class="tw-border-t-2 tw-border-t-gray-300 tw-mt-2 tw-pt-2"
                @search="filterLoads"
                @clear="clearFilters"
              />
            </TransitionChild>
          </Dialog>
        </TransitionRoot>
      </div>

      <div class="tw-hidden lg:tw-inline-block tw-w-full">
        <LoadBoardFilters v-if="!showFilterSidebar" @search="filterLoads" @clear="clearFilters" />
      </div>
    </div>

    <LoadBoardTable
      :loads="store.filteredLoads"
      :loading="store.APP_STATE === AppStates.LOADING"
      @request-booking="openRequestBookingModal"
    />
  </div>

  <RequestBookingModal
    v-if="showRequestBookingModal"
    :load="loadForBooking"
    @close="closeRequestBookingModal"
  />

  <!-- Area for notifications to show up -->
  <div
    class="tw-fixed tw-inset-0 tw-z-20 tw-flex tw-items-end tw-px-4 tw-py-6 tw-pointer-events-none sm:tw-p-6 sm:tw-items-start"
  >
    <div class="tw-w-full tw-flex tw-flex-col tw-items-center tw-space-y-4 sm:tw-items-end">
      <AppNotification
        v-for="notification in store.notifications"
        :key="notification.id"
        :notification="notification"
      />
    </div>
  </div>

  <datalist id="states">
    <option value="AL"></option>
    <option value="AK"></option>
    <option value="AZ"></option>
    <option value="AR"></option>
    <option value="CA"></option>
    <option value="CO"></option>
    <option value="CT"></option>
    <option value="DE"></option>
    <option value="DC"></option>
    <option value="FL"></option>
    <option value="GA"></option>
    <option value="HI"></option>
    <option value="ID"></option>
    <option value="IL"></option>
    <option value="IN"></option>
    <option value="IA"></option>
    <option value="KS"></option>
    <option value="KY"></option>
    <option value="LA"></option>
    <option value="ME"></option>
    <option value="MD"></option>
    <option value="MA"></option>
    <option value="MI"></option>
    <option value="MN"></option>
    <option value="MS"></option>
    <option value="MO"></option>
    <option value="MT"></option>
    <option value="NE"></option>
    <option value="NV"></option>
    <option value="NH"></option>
    <option value="NJ"></option>
    <option value="NM"></option>
    <option value="NY"></option>
    <option value="NC"></option>
    <option value="ND"></option>
    <option value="OH"></option>
    <option value="OK"></option>
    <option value="OR"></option>
    <option value="PA"></option>
    <option value="RI"></option>
    <option value="SC"></option>
    <option value="SD"></option>
    <option value="TN"></option>
    <option value="TX"></option>
    <option value="UT"></option>
    <option value="VT"></option>
    <option value="VA"></option>
    <option value="WA"></option>
    <option value="WV"></option>
    <option value="WI"></option>
    <option value="WY"></option>
  </datalist>
</template>
