import axios from 'axios'

export async function create({
  shipmentId = 0,
  bookingRequest = {
    carrierId: 0,
    rate: 0,
    equipmentTypeId: 0,
    availableDatetime: '',
    carrierName: '',
    emptyLocation: {
      city: '',
      state: '',
    },
    contact: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
  },
  recaptcha = '',
} = {}) {
  const { data: res } = await axios.post<APIResponse<Callrate>>(
    `/v1/shipments/${shipmentId}/call-rates`,
    bookingRequest,
    { headers: { 'X-QE-ReCaptcha': recaptcha } },
  )

  const callrate = res.data

  return callrate
}
