<script setup lang="ts">
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
// eslint-disable-next-line import/extensions
import { ExclamationIcon } from '@heroicons/vue/solid'
import { computed } from 'vue'
import { useStore, AppStates } from '@/store/app'

const store = useStore()

const showOverlay = computed(() => store.APP_STATE === AppStates.LOADS_UNAVAILABLE)
</script>

<template>
  <TransitionRoot as="template" :show="showOverlay">
    <Dialog as="div" class="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto">
      <div
        class="tw-flex tw-items-center tw-justify-center tw-min-h-screen tw-pt-4 tw-px-4 tw-pb-20 tw-text-center sm:tw-block sm:tw-p-0"
      >
        <TransitionChild
          as="template"
          enter="tw-ease-out tw-duration-300"
          enter-from="tw-opacity-0"
          enter-to="tw-opacity-100"
          leave="tw-ease-in tw-duration-200"
          leave-from="tw-opacity-100"
          leave-to="tw-opacity-0"
        >
          <DialogOverlay
            class="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="tw-hidden sm:tw-inline-block sm:tw-align-middle sm:tw-h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="tw-ease-out tw-duration-300"
          enter-from="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
          enter-to="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
          leave="tw-ease-in tw-duration-200"
          leave-from="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
          leave-to="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
        >
          <div
            class="tw-inline-block tw-align-bottom tw-bg-white tw-rounded-lg tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8 sm:tw-align-middle sm:tw-max-w-lg sm:tw-w-full"
          >
            <div class="tw-bg-white tw-px-4 tw-pt-5 tw-pb-4 sm:tw-p-6 sm:tw-pb-4">
              <div class="sm:tw-flex sm:tw-items-start">
                <div
                  class="tw-mx-auto tw-flex-shrink-0 flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-full tw-bg-red-100 sm:tw-mx-0 sm:tw-h-10 sm:tw-w-10"
                >
                  <ExclamationIcon class="tw-h-6 tw-w-6 tw-text-red-600" aria-hidden="true" />
                </div>
                <div class="tw-mt-3 tw-text-center sm:tw-mt-0 sm:tw-ml-4 sm:tw-text-left">
                  <DialogTitle
                    as="h3"
                    class="tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900"
                  >
                    Service Unavailable
                  </DialogTitle>
                  <div class="tw-mt-2">
                    <p class="tw-text-sm tw-text-gray-500">
                      We're currently experiencing problems getting loads to display and are working
                      to restore functionality.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="tw-bg-gray-50 tw-px-4 tw-py-3 sm:tw-px-6 sm:tw-flex sm:tw-flex-row-reverse">
              <button
                type="button"
                class="tw-mt-3 tw-w-full tw-inline-flex tw-justify-center tw-rounded-md tw-border tw-border-gray-300 tw-shadow-sm tw-px-4 tw-py-2 tw-bg-white tw-text-base tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-300 sm:tw-mt-0 sm:tw-ml-3 sm:tw-w-auto sm:tw-text-sm"
                @click="store.fetchLoads"
              >
                Retry
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
