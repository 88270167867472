import { isEqual as isSameDate, isAfter, isBefore, isDate, differenceInDays } from 'date-fns'

export function isDateBefore(
  date: string | Date,
  compareDate: string | Date,
  sameDateCountsAsBefore = true,
) {
  const formattedDate = !isDate(date) ? new Date(date) : (date as Date)
  const formattedCompareDate = !isDate(compareDate) ? new Date(compareDate) : (compareDate as Date)

  return (
    (sameDateCountsAsBefore && isSameDate(formattedDate, formattedCompareDate)) ||
    isBefore(formattedDate, formattedCompareDate)
  )
}

export function isDateAfter(
  date: string | Date,
  compareDate: string | Date,
  sameDateCountsAsAfter = true,
) {
  const formattedDate = !isDate(date) ? new Date(date) : (date as Date)
  const formattedCompareDate = !isDate(compareDate) ? new Date(compareDate) : (compareDate as Date)

  return (
    (sameDateCountsAsAfter && isSameDate(formattedDate, formattedCompareDate)) ||
    isAfter(formattedDate, formattedCompareDate)
  )
}

export function isDateBetween(
  date: string | Date,
  startDate: string | Date,
  endDate: string | Date,
  sameDateCountsForCheck = true,
) {
  return (
    isDateAfter(date, startDate, sameDateCountsForCheck) &&
    isDateBefore(date, endDate, sameDateCountsForCheck)
  )
}

export function daysSinceNow(date: Date) {
  return differenceInDays(new Date(), date)
}
