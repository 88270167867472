<script setup lang="ts">
import { PropType } from 'vue'
import { debounce } from 'lodash-es'
import { CheckIcon } from '@heroicons/vue/solid'
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/vue'
import { useStore } from '@/store/request-booking'
import SpinnerIcon from '@/components/SpinnerIcon.vue'

interface CityStateItem {
  city: string
  state: string
  cityState: string
}

defineProps({
  value: {
    type: Object as PropType<{
      city: string
      state: string
    }>,
    required: false,
    default: () => ({ city: '', state: '' }),
  },
  required: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const store = useStore()
const emit = defineEmits(['update'])

const items = ref<CityStateItem[]>([])
const isRequestActive = ref(false)
const previousSearchValue = ref('')
const fetchGeolocation = debounce(
  async (
    event: Event & {
      target: HTMLInputElement
    },
  ) => {
    if (previousSearchValue.value === event.target.value) return

    isRequestActive.value = true
    items.value = []

    try {
      const lookupResult = await store.lookupLocations(event.target.value)

      items.value = lookupResult.unwrapOr([]).map(({ city, state }: CityState) => ({
        city,
        state,
        cityState: [city, state].filter(Boolean).join(', '),
      }))
    } finally {
      previousSearchValue.value = event.target.value
      isRequestActive.value = false
    }
  },
  500,
)
</script>

<template>
  <Combobox as="div" :value="value" @change="(v) => emit('update', v)">
    <div class="tw-relative tw-mt-1">
      <ComboboxInput
        class="tw-shadow-sm tw-block tw-w-full sm:tw-text-sm tw-border-gray-300 tw-rounded-md tw-tabular-nums focus:tw-outline-none focus:tw-shadow"
        @change="fetchGeolocation"
        @blur.stop
        :display-value="(item: CityStateItem) => (item ? item.cityState : '')"
        :required="required"
        :autocomplete="'off'"
        :disabled="isRequestActive"
      />

      <div
        v-if="isRequestActive"
        class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-pointer-events-none"
      >
        <SpinnerIcon class="tw-animate-spin tw-h-5 tw-w-5" />
      </div>

      <ComboboxOptions
        v-if="items.length > 0"
        class="tw-absolute tw-z-10 tw-mt-1 tw-max-h-60 tw-w-full tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1 tw-text-base tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none sm:tw-text-sm"
      >
        <ComboboxOption
          v-for="item in items"
          :key="item.cityState"
          :value="item"
          as="template"
          v-slot="{ active, selected }"
        >
          <li
            :class="[
              'tw-relative tw-cursor-default tw-select-none tw-py-2 tw-pl-3 tw-pr-9',
              active ? 'tw-bg-blue-600 tw-text-white' : 'tw-text-gray-900',
            ]"
          >
            <span :class="['tw-block tw-truncate', selected && 'tw-font-semibold']">
              {{ item.cityState }}
            </span>

            <span
              v-if="selected"
              :class="[
                'tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-4',

                active ? 'tw-text-white' : 'tw-text-blue-600',
              ]"
            >
              <CheckIcon class="tw-h-5 tw-w-5" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>
