<script setup lang="ts">
import { PropType } from 'vue'
import { useStore } from '@/store/app'
import { formatCurrency, formatDatetime, formatNumber, formatPhone } from '@/utils/format'

import SpinnerIcon from '@/components/SpinnerIcon.vue'

defineProps({
  loads: {
    type: Array as PropType<Load[]>,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['requestBooking'])

const store = useStore()

const shouldShowRequestBookingButton = computed(
  () => store.settings.CARRIER_BOOKING_REQUEST_ENABLED,
)

const shouldShowNumPicksAndDropsCols = computed(
  () => store.settings.NUM_PICKS_AND_DROPS_COLS_ENABLED,
)

const pagination = ref({
  rowsPerPage: 250,
})

const columns = [
  {
    name: 'shipmentId',
    label: 'Load #',
    field: 'id',
    headerStyle: 'width: 6rem; max-width: 6rem;; min-width: 6rem;;',
  },
  {
    name: 'pickupDatetime',
    label: 'Pickup',
    field: 'pickupDatetime',
    headerStyle: 'width: 8.5rem; max-width: 8.5rem; min-width: 8.5rem;',
    format(val: string) {
      return formatDatetime(val)
    },
    sort(a: string, b: string) {
      return new Date(a).getTime() - new Date(b).getTime()
    },
  },
  {
    name: 'deliveryDatetime',
    label: 'Delivery',
    field: 'deliveryDatetime',
    headerStyle: 'width: 8.5rem; rem; min-width: 8.5rem;',
    format(val: string) {
      return formatDatetime(val)
    },
    sort(a: string, b: string) {
      return new Date(a).getTime() - new Date(b).getTime()
    },
  },
  {
    name: 'eqType',
    label: 'Equipment',
    field: (row: Load) => row.eqType.desc,
    headerStyle: 'width: 8rem; rem; min-width: 8rem;',
  },
  {
    name: 'numPickups',
    label: '# PU',
    field: (row: Load) => row.numPickups,
    headerStyle: 'width: 6rem; min-width: 6rem;',
  },
  {
    name: 'origin',
    label: 'Origin',
    field: (row: Load) => row.origin.city,
    headerStyle: 'width: 12rem; min-width: 12rem;',
    format(val: string, row: Load) {
      return `${val}, ${row.origin.state}`
    },
  },
  {
    name: 'numDropoffs',
    label: '# DV',
    field: (row: Load) => row.numDropoffs,
    headerStyle: 'width: 6rem; min-width: 6rem;',
  },
  {
    name: 'dest',
    label: 'Destination',
    field: (row: Load) => row.dest.city,
    headerStyle: 'width: 12rem; min-width: 12rem;',
    format(val: string, row: Load) {
      return `${val}, ${row.dest.state}`
    },
  },
  {
    name: 'miles',
    label: 'Miles (mi)',
    field: 'miles',
    headerStyle: 'width: 7rem; rem; min-width: 7rem;',
    format(val: number) {
      return `${formatNumber(val)} mi`
    },
  },
  {
    name: 'weight',
    label: 'Weight (lbs)',
    field: (row: Load) => row.weight.value,
    headerStyle: 'width: 7rem; min-width: 7rem;',
    format(val: number) {
      return `${formatNumber(val)} lbs`
    },
  },
  {
    name: 'rate',
    label: 'Rate',
    field: 'carrierRate',
    headerStyle: 'width: 6rem; min-width: 6rem;',
    format(val: number) {
      return formatCurrency(val)
    },
  },
  {
    name: 'contact',
    label: 'Contact',
    field: (row: Load) => ({ email: row.office.email, phone: formatPhone(row.office.phone) }),
    headerStyle: 'width: 9rem; min-width: 9rem;',
    sortable: false,
  },
  {
    name: 'actions',
    label: '',
    field: (row: Load) => ({
      id: row.id,
    }),
    sortable: false,
  },
]
  .filter((col) => {
    // don't include the actions column (which has the request booking button) if the setting is disabled
    switch (col.name) {
      case 'actions':
        return shouldShowRequestBookingButton.value
      case 'numPickups':
      case 'numDropoffs':
        return shouldShowNumPicksAndDropsCols.value
      default:
        return true
    }
  })
  .map((col) => ({
    ...col,
    align: 'left' as const,
    sortable: col.sortable ?? true,
  }))

function generateEmailBody(record: Load) {
  const pickup = {
    location: `${record.origin.city}, ${record.origin.state}`,
    datetime: formatDatetime(record.pickupDatetime),
  }
  const delivery = {
    location: `${record.dest.city}, ${record.dest.state}`,
    datetime: formatDatetime(record.deliveryDatetime),
  }

  const segments = [
    `${store.tenant} Load #: ${record.id}`,
    `Pick Up: ${pickup.location} - ${pickup.datetime}`,
    `Delivery: ${delivery.location} - ${delivery.datetime}`,
    `${store.tenant} Offer Rate: ${formatCurrency(record.carrierRate)}`,
    '',
    'INTERESTED CARRIER INFO',
    'Company Name:',
    'MC/DOT:',
    'Phone:',
    'Rate:',
  ]

  return segments.join('%0D')
}

function requestBooking(record: Load) {
  emit('requestBooking', record)
}
</script>

<template>
  <QTable
    id="loadsTable"
    class="load-board-table"
    virtual-scroll
    v-model:pagination="pagination"
    :rows-per-page-options="[0]"
    :virtual-scroll-sticky-size-start="40"
    row-key="shipmentId"
    table-class="tw-tabular-nums"
    :rows="loads"
    :columns="columns"
    :loading="loading"
  >
    <template #no-data>
      <span v-if="!loading"> No Loads Available </span>
    </template>
    <template #loading>
      <QInnerLoading showing>
        <h2 class="tw-flex tw-justify-center tw-items-center tw-gap-2 tw-text-xl tw-text-gray-700">
          <SpinnerIcon class="tw-animate-spin tw-w-8 tw-h-8" />Getting Available Loads
        </h2>
      </QInnerLoading>
    </template>

    <template #body-cell-contact="props">
      <QTd :props="props">
        <span v-if="!props.value.email && !props.value.phone" class="tw-text-slate-500 tw-italic">
          N/A
        </span>
        <a
          v-if="props.value.phone"
          :href="`tel:+${props.value.phone.replace(/\D/g, '')}`"
          class="tw-text-blue-600 hover:tw-text-blue-800 hover:tw-underline tw-block tw-w-fit"
        >
          {{ props.value.phone }}
        </a>
        <a
          v-if="props.value.email"
          :href="`mailto:${props.value.email}?subject=Interest in Load Board Load # ${
              props.row.shipmentId
            }&body=${generateEmailBody(props.row as Load)}`"
          class="tw-text-blue-600 hover:tw-text-blue-800 hover:tw-underline tw-block tw-w-fit"
        >
          {{ props.value.email }}
        </a>
      </QTd>
    </template>

    <template #body-cell-actions="props">
      <QTd :props="props">
        <button
          id="searchBtn"
          type="button"
          class="tw-py-2 tw-px-3 tw-rounded tw-inline-block tw-bg-blue-600 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-mr-2"
          @click="requestBooking(props.row)"
        >
          Book Now
        </button>
      </QTd>
    </template>
  </QTable>
</template>

<style lang="scss">
.load-board-table {
  /* quasar needs height so that the table will overflow */
  height: calc(100vh - 7rem);
  @media (min-width: 1024px) {
    & {
      height: calc(100vh - 10rem);
    }
  }

  /*
    we have use sass' interpolation feature to make sure !important compiles
    https://tailwindcss.com/docs/functions-and-directives#apply
  */
  @apply tw-rounded-none #{!important};

  .q-table__bottom {
    @apply tw-bg-white tw-text-black;
    @apply tw-text-xs sm:tw-text-base #{!important};
  }

  thead tr {
    @apply tw-h-8;

    &:first-child th {
      @apply tw-top-0;
    }

    th {
      background-color: #377ab2;
      @apply tw-w-1/12;
      @apply tw-sticky tw-z-10 tw-px-2 tw-text-white tw-uppercase;
      @apply tw-text-sm sm:tw-text-lg #{!important};

      &:first-child {
        @apply tw-pl-4;
      }
    }
  }

  tbody tr {
    &:nth-child(even) {
      @apply tw-bg-gray-100;
    }

    td {
      height: 40px;
      @apply tw-text-xs lg:tw-text-sm #{!important};
      @apply tw-px-2;

      &:first-child {
        @apply tw-pl-4;
      }
    }
  }
}
</style>
