import axios from 'axios'
import localforage from 'localforage'
import { daysSinceNow } from '@/utils/date-compare'

interface ServerTenantData {
  abbr: string
  logo: string
  token: string
  name: string
  settings: {
    loadBoard: {
      CARRIER_BOOKING_REQUEST_ENABLED: boolean
      LINKED_TENANT_ID: string
      CLIENT_DISPLAY_NAME: string
      BOOKING_MODAL_DISPLAY_ALL_STOPS: boolean
      BOOKING_MODAL_DISPLAY_SPECIAL_INSTRUCTIONS: boolean
      BOOKING_MODAL_ADDITIONAL_DISCLAIMER: string
      NUM_PICKS_AND_DROPS_COLS_ENABLED: boolean
      SHOW_LOAD_BOARD_LEGAL_DISCLAIMER: boolean
    }
  }
}

// these are links between MT and MTL load boards for beta and prod. this is a temporary solution and will be
// replaced with a lookup of some sort in the near future
const linkedTenants: { [key: string]: string } = {
  '36802e68-d071-11ec-87b8-00505695abf7': 'b5160d0a-5e90-11ec-8f38-f01fafe7d568',
  'b5160d0a-5e90-11ec-8f38-f01fafe7d568': '36802e68-d071-11ec-87b8-00505695abf7',
  '820e0e8b-dd58-11ec-ae0e-1418775a4a62': 'f7b1f399-74dd-11ec-aa95-1418775a4a62',
  'f7b1f399-74dd-11ec-aa95-1418775a4a62': '820e0e8b-dd58-11ec-ae0e-1418775a4a62',
  '45508f83-680d-4d62-9861-afcf8ced03fd': 'test',
}
if (import.meta.env.VITE_TEST_LINKED_TENANT_ID) {
  const urlTenantId = window.location.pathname.replace(/^\//, '')
  linkedTenants[urlTenantId] = import.meta.env.VITE_TEST_LINKED_TENANT_ID as string
}
export async function get(tenantId = '') {
  const tenantData = {
    tenant: '',
    companyName: '',
    logo: '',
    token: '',
    linkedTenantId: '',
    settings: {
      CARRIER_BOOKING_REQUEST_ENABLED: false,
      LINKED_TENANT_ID: '',
      CLIENT_DISPLAY_NAME: '',
      BOOKING_MODAL_DISPLAY_ALL_STOPS: false,
      BOOKING_MODAL_DISPLAY_SPECIAL_INSTRUCTIONS: false,
      BOOKING_MODAL_ADDITIONAL_DISCLAIMER: '',
      NUM_PICKS_AND_DROPS_COLS_ENABLED: false,
      SHOW_LOAD_BOARD_LEGAL_DISCLAIMER: false,
    },
  }
  const tenantFromLocalStorage = await localforage.getItem<{
    id: string
    version: string
    date: number
    data: typeof tenantData
  }>('tenant')

  // we'll use the cache if it's for the same tenant we're getting data for and if the cached data
  //  is up to a week old. it'll be ok if something happens that results in the tenant id being
  //  inactivated while the cache is still seen as valid because subsequent requests (like getting loads)
  //  will still be checked to make sure the token is active
  if (
    tenantFromLocalStorage &&
    tenantFromLocalStorage.id === tenantId &&
    tenantFromLocalStorage.version === __APP_VERSION__ &&
    tenantFromLocalStorage.data.token?.length &&
    daysSinceNow(new Date(tenantFromLocalStorage.date)) <= 7
  ) {
    return tenantFromLocalStorage.data
  }

  const { data: res } = await axios.get<APIResponse<ServerTenantData>>(`/v1/tenants/${tenantId}`)

  tenantData.tenant = res.data.abbr
  tenantData.logo = res.data.logo
  tenantData.token = res.data.token
  tenantData.companyName = res.data.name
  tenantData.linkedTenantId = linkedTenants[tenantId] || ''
  tenantData.settings = { ...res.data.settings.loadBoard }

  await localforage.setItem('tenant', {
    id: tenantId,
    data: tenantData,
    version: __APP_VERSION__,
    date: new Date().toISOString(),
  })

  return tenantData
}
