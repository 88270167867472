<script setup lang="ts">
import { reactive } from 'vue'
import { useStore } from '@/store/app'

interface Filters {
  originCity: string
  originState: string
  originRadius: number | null
  destCity: string
  destState: string
  destRadius: number | null
  eqType: string
  pickupDateFrom: string
  pickupDateTo: string
}

const store = useStore()
const emit = defineEmits(['search', 'clear'])

const filters: Filters = reactive({
  originCity: store.filters.originCity,
  originState: store.filters.originState,
  originRadius: store.filters.originRadius,
  destCity: store.filters.destCity,
  destState: store.filters.destState,
  destRadius: store.filters.destRadius,
  eqType: store.filters.eqType,
  pickupDateFrom: store.filters.pickupDateFrom,
  pickupDateTo: store.filters.pickupDateTo,
})

function search() {
  emit('search', filters)
}

function clear() {
  filters.originCity = ''
  filters.originState = ''
  filters.originRadius = null
  filters.destCity = ''
  filters.destState = ''
  filters.destRadius = null
  filters.eqType = ''
  filters.pickupDateFrom = ''
  filters.pickupDateTo = ''

  emit('clear')
}
</script>

<template>
  <!--
    todo: need to fix how the filters look when shrinking the screen size but before it hits the `lg` breakpoint
      maybe something with a ResizeObserver. useResizeObserver?
  -->
  <form
    id="filterForm"
    class="tw-flex tw-flex-wrap lg:tw-flex-nowrap tw-gap-y-4 tw-justify-between tw-w-full"
    @submit.prevent="search"
  >
    <div id="filters" class="tw-flex tw-flex-wrap tw-gap-x-2 tw-gap-y-2 tw-w-full">
      <div class="tw-w-full lg:tw-w-40">
        <label class="filter-label" for="originCityFilter">Origin City</label>
        <input
          v-model.trim="filters.originCity"
          id="originCityFilter"
          type="text"
          placeholder="City"
          class="filter-element"
        />
      </div>

      <div class="tw-w-full lg:tw-w-24">
        <label class="filter-label" for="originStateFilter">Origin State</label>
        <input
          v-model.trim="filters.originState"
          id="originStateFilter"
          type="text"
          placeholder="State"
          list="states"
          class="filter-element"
        />
      </div>

      <div class="tw-w-full lg:tw-w-24">
        <label class="filter-label" for="originRadiusFilter">Origin Radius</label>
        <input
          v-model.number="filters.originRadius"
          id="originRadiusFilter"
          type="number"
          placeholder="Miles"
          min="1"
          class="filter-element"
        />
      </div>

      <div class="tw-w-full lg:tw-w-40">
        <label class="filter-label" for="destCityFilter">Dest City</label>
        <input
          v-model.trim="filters.destCity"
          id="destCityFilter"
          type="text"
          placeholder="City"
          class="filter-element"
        />
      </div>

      <div class="tw-w-full lg:tw-w-24">
        <label class="filter-label" for="destStateFilter">Dest State</label>
        <input
          v-model.trim="filters.destState"
          id="destStateFilter"
          type="text"
          placeholder="State"
          list="states"
          class="filter-element"
        />
      </div>

      <div class="tw-w-full lg:tw-w-24">
        <label class="filter-label" for="destRadiusFilter">Dest Radius</label>
        <input
          v-model.number="filters.destRadius"
          id="destRadiusFilter"
          type="number"
          placeholder="Miles"
          min="1"
          class="filter-element"
        />
      </div>

      <div class="tw-w-full lg:tw-w-32">
        <label class="filter-label" for="equipmentFilter">Equipment</label>
        <select v-model="filters.eqType" id="equipmentFilter" class="filter-element">
          <option value="">- All -</option>
          <option value="VAN">VAN</option>
          <option value="REEFER">REEFER</option>
          <option value="FLATBED">FLATBED</option>
          <option value="POWER">POWER</option>
          <option value="STEPDECK">STEPDECK</option>
          <option value="CONESTOGA">CONESTOGA</option>
          <option value="PUP">PUP</option>
          <option value="CONTAINER">CONTAINER</option>
        </select>
      </div>

      <div class="tw-w-full lg:tw-w-72">
        <label class="filter-label" for="pickupDateFilterFrom">Pickup Date</label>
        <div class="tw-flex tw-gap-1">
          <input
            v-model.trim="filters.pickupDateFrom"
            id="pickupDateFilterFrom"
            type="date"
            placeholder="From"
            class="filter-element !tw-w-72"
          />
          <span class="tw-text-xs tw-justify-self-center tw-self-center tw-text-center tw-w-4">
            <!-- &#8212; is an em dash (—)-->
            &#8212;
          </span>
          <input
            v-model.trim="filters.pickupDateTo"
            id="pickupDateFilterTo"
            type="date"
            placeholder="To"
            class="filter-element !tw-w-72"
          />
        </div>
      </div>
    </div>

    <div
      id="filterButtons"
      class="tw-w-full lg:tw-w-fit tw-whitespace-nowrap tw-self-end tw-text-right"
    >
      <button
        id="searchBtn"
        type="submit"
        class="btn tw-bg-blue-600 hover:tw-bg-blue-700 tw-text-white tw-font-bold tw-mr-2"
      >
        Search
      </button>
      <button
        id="clearBtn"
        @click="clear"
        class="btn tw-bg-white tw-text-blue-600 hover:tw-text-blue-700 tw-font-semibold tw-border tw-border-blue-300 hover:tw-border-blue-500"
      >
        Clear
      </button>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.filter-label {
  @apply tw-block tw-text-gray-700 tw-text-sm tw-font-semibold tw-mb-2;
}
.filter-element {
  @apply tw-shadow tw-text-sm tw-appearance-none tw-w-full tw-border-0 tw-rounded tw-py-2 tw-px-2 tw-text-gray-700 tw-leading-tight;

  &:focus {
    @apply tw-outline-none tw-shadow;
  }
}

.btn {
  @apply tw-py-2 tw-px-3 tw-rounded tw-inline-block;
}
</style>
