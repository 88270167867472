import { RouteLocation } from 'vue-router'
import { useStore, AppStates } from '@/store/app'

import LoadBoard from './views/LoadBoard.vue'
import NotFound from './views/NotFound.vue'
import AppUnavailable from './views/AppUnavailable.vue'

/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [
  {
    path: '/:id',
    meta: { title: 'Load Board' },
    component() {
      const store = useStore()

      // this works since beforeEnter runs before the component is returned
      if (store.APP_STATE === AppStates.TENANT_NOT_FOUND) {
        return Promise.resolve(NotFound)
      }

      if (store.APP_STATE === AppStates.UNAVAILABLE) {
        return Promise.resolve(AppUnavailable)
      }

      return Promise.resolve(LoadBoard)
    },

    // beforeEnter will run before we fully get into the view.
    // we'll authenticate the user and load up data that the view can use
    async beforeEnter(to: RouteLocation) {
      const tenantId = to.params.id as string

      const store = useStore()

      const isAuthenticated = await store.authenticate(tenantId)

      if (!isAuthenticated) {
        // get out of the guard if we can't set up the tenant
        return true
      }

      // kick off the load fetch and don't want on the result.
      store.fetchLoads()

      return true
    },

    // example of route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import('./views/About.vue')
  },
  { path: '/:path(.*)', component: NotFound },
]
