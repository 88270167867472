<script setup lang="ts">
import { useStore } from '@/store/app'

const store = useStore()
const { tenant, logo, linkedTenantId, companyName, settings } = store

const loadBoardUrl = linkedTenantId ? `${window.location.origin}/${linkedTenantId}` : ''
</script>

<template>
  <header id="header" class="tw-bg-white tw-min-h-20 tx-max-h-44">
    <div class="sm:tw-flex tw-justify-between tw-px-2 tw-pt-3">
      <div class="tw-flex tw-gap-x-4">
        <img
          v-if="logo"
          loading="eager"
          class="tw-inline-block tw-h-16 tw-max-w-48"
          :src="`https://qe-edge-resources.s3.amazonaws.com/images/logos/${logo}`"
          :alt="`Logo for ${tenant}`"
        />
        <div class="tw-flex tw-flex-col">
          <h1 class="tw-text-3xl tw-text-gray-900 tw-leading-6 tw-font-bold">Load Board</h1>
          <span class="sm:tw-hidden" v-if="loadBoardUrl">
            <a
              :href="loadBoardUrl"
              target="_blank"
              rel="noopener"
              class="tw-text-blue-600 hover:tw-text-blue-800 hover:tw-underline tw-cursor-pointer"
            >
              Additional loads may be available.
            </a>
          </span>
        </div>
      </div>

      <div class="sm:tw-block tw-self-end" v-if="loadBoardUrl">
        To view additional available loads, please visit our sister company's
        <a
          :href="loadBoardUrl"
          target="_blank"
          rel="noopener"
          class="tw-text-blue-600 hover:tw-text-blue-800 hover:tw-underline tw-cursor-pointer"
        >
          Load Board.
        </a>
      </div>
    </div>
    <div
      class="sm:tw-block tw-justify-between tw-px-2 tw-pt-3 tw-text-xs"
      v-if="settings.SHOW_LOAD_BOARD_LEGAL_DISCLAIMER"
    >
      <p class="tw-font-bold">Restrictions on Use:</p>
      <p class="tw-font-light">
        The information, including but not limited to, load details, rates, and availability, posted
        on this load board is proprietary to {{ companyName }} and it's subsidiaries and is
        protected by applicable intellectual property laws. Access to and use of this load
        information are granted solely for the purpose of facilitating direct business transactions
        between carriers and {{ companyName }} and it's subsidiaries. Any copying, redistribution,
        retransmission, or publication of this load information, or any part thereof, without the
        express written consent of {{ companyName }} is strictly prohibited. This includes posting
        our load information on other websites, platforms, or digital and print mediums.
        <span class="tw-font-medium"
          >Unauthorized use of this site and its load information may give rise to a claim for
          damages and/or be a criminal offense.</span
        >
      </p>
    </div>
  </header>
</template>
