<script setup lang="ts">
import { PropType, ref } from 'vue'
import { formatDatetimeFull } from '@/utils/format'

const props = defineProps({
  stops: {
    type: Array as PropType<
      {
        id: number
        city: string
        state: string
        stopType: string
        sequence: number
        reqApptDatetime: string
      }[]
    >,
    required: true,
  },
})

const stops = props.stops?.map((s) => ({
  ...s,
  reqApptDatetime: formatDatetimeFull(s.reqApptDatetime || ''),
}))

const isShowingStops = ref(false)
</script>

<template>
  <!-- The header-style here centers the title above the stop divs and makes this look neater.  
  Without it, the arrow icon pushes the title off-center.  -->
  <QExpansionItem
    v-model="isShowingStops"
    label="Stop Details"
    :header-style="{ marginLeft: '4%' }"
    :caption="
      isShowingStops ? 'Click here to hide stops.' : 'Click here to view all stops for this load.'
    "
  >
    <div v-for="stop in stops" :key="stop.id" :value="stop" class="stop-div">
      <label class="tw-font-semibold tw-text-lg tw-uppercase tw-block tw-mb-2"
        >Stop # {{ stop.sequence }} - {{ stop.stopType }}</label
      >
      <div>{{ stop.city }}, {{ stop.state }}</div>
      <div>{{ stop.reqApptDatetime }}</div>
    </div>
  </QExpansionItem>
</template>

<style scoped>
.stop-div {
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  width: 225px;
  margin: 0 auto 5px auto;
}
</style>
