<script setup lang="ts">
import { PropType } from 'vue'
import { CheckCircleIcon } from '@heroicons/vue/outline'
import { XIcon } from '@heroicons/vue/solid'
import { useStore } from '@/store/app'

const store = useStore()

const props = defineProps({
  notification: {
    type: Object as PropType<AppNotification>,
    required: true,
  },
})

const show = ref(true)

watch(show, (val) => {
  if (!val) {
    nextTick(() => {
      store.removeNotification(props.notification.id as string)
    })
  }
})
</script>

<template>
  <transition
    enter-active-class="tw-transform tw-ease-out tw-duration-300 tw-transition"
    enter-from-class="tw-translate-y-2 tw-opacity-0 sm:tw-translate-y-0 sm:tw-translate-x-2"
    enter-to-class="tw-translate-y-0 tw-opacity-100 sm:tw-translate-x-0"
    leave-active-class="tw-transition tw-ease-in tw-duration-100"
    leave-from-class="tw-opacity-100"
    leave-to-class="tw-opacity-0"
  >
    <div
      v-if="show"
      class="tw-max-w-sm tw-w-full tw-bg-white tw-shadow-lg tw-rounded-lg tw-pointer-events-auto tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-overflow-hidden"
    >
      <div class="tw-p-4">
        <div class="tw-flex tw-items-start">
          <div class="tw-flex-shrink-0">
            <CheckCircleIcon class="tw-h-6 tw-w-6 tw-text-green-400" aria-hidden="true" />
          </div>
          <div class="tw-ml-3 tw-w-0 tw-flex-1 tw-pt-0.5">
            <p class="tw-text-sm tw-font-medium tw-text-gray-900">{{ notification.header }}</p>
            <p class="tw-mt-1 tw-text-sm tw-text-gray-500">
              {{ notification.body }}
            </p>
          </div>
          <div class="tw-ml-4 tw-flex-shrink-0 tw-flex">
            <button
              type="button"
              @click="show = false"
              class="tw-bg-white tw-rounded-md tw-inline-flex tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500"
            >
              <span class="tw-sr-only">Close</span>
              <XIcon class="tw-h-5 tw-w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
