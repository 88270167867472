import axios from 'axios'
import localforage from 'localforage'
import { daysSinceNow } from '@/utils/date-compare'

export async function getCoords(city = '', state = '') {
  if (!city || !state) {
    return {
      lat: 0,
      lon: 0,
    }
  }

  const cityStateCoordKey = `${city}-${state}`.replace(/\s/g, '').toLowerCase()

  const cachedCoords = await localforage.getItem<{
    version: string
    date: number
    data: { [key: string]: { lat: number; lon: number } }
  }>('coords')

  // we'll use the cache if the cached data is up to a week old
  if (
    cachedCoords &&
    cachedCoords.version === __APP_VERSION__ &&
    daysSinceNow(new Date(cachedCoords.date)) <= 7 &&
    Object.getOwnPropertyDescriptor(cachedCoords.data, cityStateCoordKey) &&
    cachedCoords.data[cityStateCoordKey]?.lat &&
    cachedCoords.data[cityStateCoordKey]?.lon
  ) {
    return cachedCoords.data[cityStateCoordKey]
  }

  const coordData = cachedCoords?.data || {}

  try {
    const { data: res } = await axios.get(`/v1/lookups/geocode?city=${city}&state=${state}`)

    const { lat, lon } = res.data

    coordData[cityStateCoordKey] = { lat, lon }

    await localforage.setItem('coords', {
      data: coordData,
      version: __APP_VERSION__,
      date: new Date().toISOString(),
    })

    return { lat, lon }
  } catch (err) {
    if (!(err instanceof Error)) {
      console.error(err)
    }

    return { lat: 0, lon: 0 }
  }
}

const locationCache: { [key: string]: CityState[] } = {}

// use the search value and look up a corresponding city/state. attempts to hit an internal cache first and will
// make a request to the API if the cache is empty.
export async function getLocation(searchValue = '') {
  if (!searchValue.length) {
    return []
  }

  if (!locationCache[searchValue]) {
    const { data: response } = await axios.get<APIResponse<CityState[]>>(
      `/v1/lookups/geolocation?searchValue=${searchValue}`,
    )
    locationCache[searchValue] = response.data.map((geo: { city: string; state: string }) => ({
      city: geo.city,
      state: geo.state,
    }))
  }

  return locationCache[searchValue]
}
